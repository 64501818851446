

export class KeyedCollection<T>  {
    private items: { [index: number]: T } = {};
 
    private count: number = 0;
 
    public ContainsKey(key: number): boolean {
        return this.items.hasOwnProperty(key);
    }
 
    public Count(): number {
        return this.count;
    }
 
    public Add(key: number, value: T) {
        if(!this.items.hasOwnProperty(key))
             this.count++;
 
        this.items[key] = value;
    }
  
    public Item(key: number): T {
        return this.items[key];
    }
 
   
}