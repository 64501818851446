import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { GenericListItemContract } from 'projects/shared/models/GenericListItemContract';
import { GenericResponseContract } from 'projects/shared/models/GenericResponseContract';
import { Globals } from 'projects/shared/models/Globals';
declare var archaeologicalSiteSectionResources: any;
declare var $: any;

@Component({
  selector: 'home-archaeological-site-section',
  templateUrl: './home-archaeological-site-section.component.html',
  styleUrls: []
})

export class HomeArchaeologicalSiteSectionComponent {
  featuredArchaeologicalSite: GenericListItemContract = new GenericListItemContract();
  globals;
  rtl = Globals.Culture == "ar";
  slideConfig = {
    "infinite": true,
    "slidesToShow": 2,
    "slidesToScroll": 2,
    "speed": 0,
    "arrows": true,
    "nextArrow": ".next",
    "prevArrow": ".back",
    "rtl": this.rtl,
    "responsive": [
      {
        "breakpoint": 825,
        "settings": {
          "infinite": false,
          "slidesToShow": 1,
          "slidesToScroll": 1,
          "speed": 500,
          "arrows": false,
          "centerMode": true,
          "centerPadding": '0px'
        }
      }
    ]
  };
  archaeologicalSiteSectionResources;
  selectedIndex;

  constructor(private apiService: AppService) { }
  ngOnInit() {
    this.globals = Globals;
    this.archaeologicalSiteSectionResources = archaeologicalSiteSectionResources;
    this.getFeaturedArchaeologicalSite();
  }
  public getFeaturedArchaeologicalSite() {
    this.apiService.getFeaturedArchaeologicalSite()
      .subscribe((response: GenericResponseContract<GenericListItemContract>) => {
        this.featuredArchaeologicalSite = response.Data;
      });
  }

  public _openPopup($event) {
    this.selectedIndex = $event;
    $("#homeMediaModal").modal()

  }

  setReflectedIndex($event) {
    this.selectedIndex = $event;
  }

  reflectPopupShowMore() {

  }

}




