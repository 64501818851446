

export class ServicesData {
    constructor() { }
    collection: Services[] = [];
    totalCount: number = 0;

}

class Services {

    id: number
    serviceCode: string;
    serviceName: string;
    description: string;
    image: string;
    categories: string;
    profileTypes: string;
    ServiceURL: string;
}
