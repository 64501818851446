import { Component, AfterViewInit, Input, ViewChild, Output, EventEmitter, NgZone } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { KeyedCollection } from '../../models/KeyedCollection';
import { Globals } from '../../models/Globals';
import {SwiperDirective, SwiperConfigInterface
} from 'ngx-swiper-wrapper';


declare let VRView: any;
declare var $: any;

@Component({
  selector: 'media-popup',
  templateUrl: './media-Item-popup.component.html',
  styleUrls: [],
})
export class MediaItemPopupComponent implements AfterViewInit {

  public config: SwiperConfigInterface;

  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  items;
  get mediaItems(): any {
    return this.items;
  }
  @Input()
  set mediaItems(val: any) {
    this.items = val;
    this.directiveRef.update();
    if (this.items) {
      this.initiateExternalLinks();
      $("media-popup").appendTo("body");
      this.initiateViewers(this.directiveRef.getIndex());
    }
  }

  _selectedIndex;
  get selectedIndex(): any {
    return this._selectedIndex;
  }
  @Input()
  set selectedIndex(val: any) {
    if (val != undefined && val != null) {

      setTimeout(() => {
        this.directiveRef.config.effect = "fade";
        this.directiveRef.config.speed = 3000;
        this.directiveRef.setIndex(val);
        this.directiveRef.update();
        setTimeout(() => {
          this.directiveRef.config.effect = "slide";
          this.directiveRef.config.speed = 800;
          this.directiveRef.update();
          this.initiateViewers(val);
        }, 150);
      }, 150);

    }
  }

  @Output() reflectedIndex = new EventEmitter();
  @Output() reflectShowMore = new EventEmitter();
  public currentURL: KeyedCollection<SafeUrl>;
  className: string;
  viewer: any;
  location: string = Globals.InnerLocalizedResources.Location;
  duration: string = Globals.InnerLocalizedResources.Duration;
  relatedTo: string = Globals.InnerLocalizedResources.RelatedTo;

  constructor(private sanitizer: DomSanitizer, private _ngZone: NgZone) {
    this.currentURL = new KeyedCollection<number>();
  }

  ngOnInit() { }

  ngAfterViewInit() {
    $('#homeMediaModal').on('shown.bs.modal', function (e) {
      $("audio").not($(".audioplayer audio")).audioPlayer()
    });

    var self = this;
    $('#homeMediaModal').on('hidden.bs.modal', function () {
      var currentIndex = self.directiveRef.getIndex();
      self.reflectedIndex.emit(null);
      self.resetMedia();
    });
  }

  getVideoURL(videoId: number, videoURL: string) {
    this.currentURL.Add(
      videoId,
      this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + videoURL.split('v=')[1] + "?enablejsapi=1&version=3&playerapiid=ytplayer")
    );
  }

  get360ImageURL(imageId, imageURL: string) {
    this.currentURL.Add(
      imageId,
      this.sanitizer.bypassSecurityTrustResourceUrl("https://my.matterport.com/show/?m=" + imageURL.split('m=')[1] + "&minimap=1")
    );
  }

  initiateExternalLinks() {
    for (let item of this.items) {
      if (item.MediaType == 'videoYoutube') {
        this.getVideoURL(item.Id, item.ExternalLink);
      }
      else if (item.MediaType == 'image360Link') {
        this.get360ImageURL(item.Id, item.ExternalLink);
      }
    }
  }

  initiateViewers(selectedIndex) {
    $(window).trigger('resize');
    this.detectItemNum(selectedIndex);

    //ForFady: this code crashed on ie
    // $(".itemDisc.txtSection").mCustomScrollbar({
    //   axis: "y",
    //   advanced: {
    //     autoExpandHorizontalScroll: true
    //   },
    //   scrollButtons: {
    //     enable: true,
    //     scrollType: "stepped"
    //   },
    //   mouseWheel: {
    //     preventDefault: false
    //   }
    // });

    // for (let item of this.items) {
    // 		if (item.MediaType == 'image360') {
    // 			$(`#${'vrview' + item.Id}`).empty();
    // 			new VRView.Player(`#${'vrview' + item.Id}`, {
    // 				image: "http://cors-anywhere.herokuapp.com/" + window.location.origin +item.MediaFile.src
    // 			});
    // 		}
    // }
    var self = this;

    $('#homeMediaModal').on('shown.bs.modal', function (e) {
      self.config = {
        observer: true,
        direction: "horizontal",
        loop: false,
        grabCursor: true,
        speed: 1500,
        parallax: false,
        autoplay: false,
        effect: "slide",
        resistance: true,
        resistanceRatio: 0,
        preventClicks: true,
        simulateTouch: false,
        preventClicksPropagation: false,
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          nextEl: '.popupNextArrow',
          prevEl: '.popupPrevArrow',

        },
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
      }
    });

  }

  // Detect if the selected item is first or last item
  detectItemNum($event) {
    if ($event == this.items.length - 1) {
      this.reflectShowMore.emit();
    }

    setTimeout(() => {
      $('.popupNextArrow, .popupPrevArrow').removeClass('dimmed');
      if ($event == 0) {
        $('.popupPrevArrow').addClass('dimmed');

        if (this.items.length == 1) {
          $('.popupNextArrow').addClass('dimmed');
        }
      }
      else if ($event == this.items.length - 1) {

        $('.popupNextArrow').addClass('dimmed');
      } else {
        $('.popupNextArrow, .popupPrevArrow').removeClass('dimmed');
      }

    }, 150);
    this.resetMedia();
  }

  resetMedia() {
    var sounds = document.getElementsByTagName('audio');
    for (var i = 0; i < sounds.length; i++) sounds[i].pause();

    var videos = document.getElementsByTagName('video');
    for (var i = 0; i < videos.length; i++) videos[i].pause();

    var videosYoutube = $('.youtube-video');
    var videosLength = videosYoutube.length;
    if (videosLength && videosLength > 0) {
      for (var i = 0; i < videosLength; i++) {
        videosYoutube[i].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
      }
    }
  }

}


