import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-product-rating',
	templateUrl: './product-rating.component.html',
	styleUrls: ['./product-rating.component.css']
})

export class ProductRatingComponent implements OnInit {

	public ratingAverage; 
	get RatingAverage(): any { 
	  return this.ratingAverage;
	}
	@Input()
	set RatingAverage(val: any) {
	  this.ratingAverage =  val;
	}

	public ratingCountNumber; 
	get RatingCountNumber(): any { 
	  return this.ratingCountNumber;
	}
	@Input()
	set RatingCountNumber(val: any) {
	  this.ratingCountNumber =  val;
	}

	public productCategory; 
	get ProductCategory(): any { 
	  return this.productCategory;
	}
	@Input()
	set ProductCategory(val: any) {
	  this.productCategory =  val;
	}
	
	

	constructor() { }

	ngOnInit() {
	}
}
