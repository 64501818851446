import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../services/app.service';
import { Globals } from 'projects/shared/models/Globals';
declare var $: any;

@Component({
  selector: 'home-event-section',
  templateUrl: './home-event-section.component.html',
  styleUrls: []
})
export class HomeEventSectionComponent implements OnInit {
  featuredEvents: any;
  globals;

  public eventDate: any;
  public OnThisDayDate: any;
  public OnThisDayTitle: any = "";
  public OnThisDayDescription: any = "";

  rtl = Globals.Culture == "ar";
  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "arrows": true,
    "nextArrow": ".eventNextArrow",
    "prevArrow": ".eventPrevArrow",
    "centerMode": true,
    "infinite": false,
    "centerPadding": '0px',
    "rtl": this.rtl,
    "responsive": [
      {
        "breakpoint": 825,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1,
          "arrows": false,
          "centerMode": true,
          "infinite": false,
          "centerPadding": '0px',
        }
      }
    ]
  };

  constructor(private apiService: AppService) {
    this.globals = Globals;
  }

  ngOnInit() {
    this.getFeaturedEvents();
  }

  ngAfterViewInit() {
    this.initOnthisDay(0);
  }

  public getFeaturedEvents() {
    this.apiService.getFeaturedEvents()
      .subscribe(response => {
        this.featuredEvents = response,
          this.initOnthisDay(0);
      });
  }


  public beforeChange($event) {
    this.initOnthisDay($event.nextSlide);

    this.animate();
  }

  initOnthisDay(index: number) {
    if (this.featuredEvents && this.featuredEvents.Data && this.featuredEvents.Data.ListItems[index]) {
      this.eventDate = this.featuredEvents.Data.ListItems[index].Date;

      if (this.featuredEvents.Data.ListItems[index].InnerList) {
        var innerList = this.featuredEvents.Data.ListItems[index].InnerList.ListItems[0];
        this.OnThisDayDate = innerList.Date.FromYear + " " + innerList.Date.FromEra;
        this.OnThisDayTitle = innerList.Title;
        this.OnThisDayDescription = innerList.Description;
      }
      else {
        this.OnThisDayTitle = null;
      }
    }
  }

  animate() {
    $('.featuredEventDate > span, .onThisDayDetails *').css('opacity', '.3').animate({
      opacity: '1'
    }, 1000);
  }

}
