import { Injectable } from  '@angular/core';
import { HttpClient, HttpHeaders } from  '@angular/common/http';
import { Globals } from 'projects/shared/models/Globals';


@Injectable({
providedIn:  'root'
})

export  class  AppService {

    constructor(private  httpClient:  HttpClient) {
    }
    
    getFeaturedMuseum(){
        return  this.httpClient.get(`${Globals.BaseUrl}/HomeWebAPI/GetFeaturedMuseum`, {
            headers: new HttpHeaders().set('culture', Globals.Culture)
        });
    }
    getFeaturedCities(){
        return  this.httpClient.get(`${Globals.BaseUrl}/HomeWebAPI/GetFeaturedCities`, {
            headers: new HttpHeaders().set('culture', Globals.Culture)
        });
    }

    getFeaturedUnderwaterTreasures(){
        return  this.httpClient.get(`${Globals.BaseUrl}/HomeWebAPI/GetFeaturedUnderwaterTreasures`, {
            headers: new HttpHeaders().set('culture', Globals.Culture)
        });
    }

    getFeaturedArchaeologicalSite(){
        return  this.httpClient.get(`${Globals.BaseUrl}/HomeWebAPI/GetFeaturedArchaeologicalSite`, {
            headers: new HttpHeaders().set('culture', Globals.Culture)
        });
    }

    getFeaturedEvents(){
        return  this.httpClient.get(`${Globals.BaseUrl}/HomeWebAPI/GetFeaturedEvents`, {
            headers: new HttpHeaders().set('culture', Globals.Culture)
        });
    }

    getChildrenEvents(){
        return  this.httpClient.get(`${Globals.BaseUrl}/HomeWebAPI/GetChildrenEvents`, {
            headers: new HttpHeaders().set('culture', Globals.Culture)
        });
    }

    getFeaturedAnnouncements(){
        return  this.httpClient.get(`${Globals.BaseUrl}/HomeWebAPI/GetFeaturedAnnouncements`, {
            headers: new HttpHeaders().set('culture', Globals.Culture)
        });
    }

    getFeaturedproducts(){
        return  this.httpClient.get(`${Globals.BaseUrl}/HomeWebAPI/GetFeaturedProducts`, {
            headers: new HttpHeaders().set('culture', Globals.Culture)
        });
    }

    getBestsellingProducts(){
        return  this.httpClient.get(`${Globals.BaseUrl}/HomeWebAPI/GetBestSellingProducts`, {
            headers: new HttpHeaders().set('culture', Globals.Culture)
        });
    }
    
    AddBasketItem(productKey) {
        var __RequestVerificationToken = document.getElementsByName("__RequestVerificationToken")[0].getAttribute("value");
        const httpOptions = {
          headers: new HttpHeaders({
            'X-Requested-With':'XMLHttpRequest'
          })};
        const formData = new FormData();
        formData.append('ProductKey', productKey);
        formData.append('__RequestVerificationToken', __RequestVerificationToken);
  
        return this.httpClient.post(`${Globals.BaseMerchelloUrl}/StoreBasket/AddBasketItem`, formData,httpOptions);
      }
      GetCustomerBasketInfo(){
        return this.httpClient.get(`${Globals.BaseUrl}/EshopWebAPI/GetCurrentCustomerBasketInfo`);
      } 
      
      
      GetEServices(){

        var body =JSON.stringify({pageCriteria:{pageNumber:0,pageSize:50 , allowPaging: true}, sortCriteria:{field:'ServiceOrder',dir:1}});

        let headers = new HttpHeaders({
          'accept':'application/json, text/plain, */*',
          'content-type': 'application/json',
          'culture': 'en',
        });

        let options = { headers: headers };

        return this.httpClient.post<any>(`${Globals.EServicesBaseUrl}api/serviceCatalog/filter` , body , options);


      }
}

