import { Component, Input, ElementRef, SimpleChange } from '@angular/core';
import { AppService } from 'projects/HomeApp/src/app/services/app.service';
import { Globals } from 'projects/shared/models/Globals';
import { ServicesData } from './ServicesData.Model';
// import { ServiceManageConfig } from 'serv';
// declare var homeExcavationServicesSectionResources: any;

declare var $: any;

@Component({
  selector: 'home-excavation-services-section',
  templateUrl: './home-excavation-services-section.component.html',
  styleUrls: []
})

export class HomeExcavationServicesSectionComponent {
  globals;
  rtl = Globals.Culture == "ar";
  services: ServicesData=new ServicesData();
  slideConfig = {
    "infinite": true,
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "arrows": true,
    "nextArrow": ".servicesNext",
    "prevArrow": ".servicesBack",
    "rtl": this.rtl,
    "responsive": [
      {
        "breakpoint": 825,
        "settings": {
          "infinite": false,
          "slidesToShow": 1,
          "slidesToScroll": 1,
          "speed": 500,
          "arrows": false,
          "centerMode": true,
          "centerPadding": '0px'
        }
      }
    ]
  };
  // homeExcavationServicesSectionResources;


  constructor(private elementRef: ElementRef, private Service: AppService) {
    this.globals = Globals;
    // this.services = window[this.elementRef.nativeElement.getAttribute('missionservices')]  as any;

    
  }
  ngOnChanges(changes: SimpleChange) {

  }
  ngOnInit() {
    // this.getservices();
    this.Service.GetEServices().subscribe(response => {
      this.services = response;
      if(this.services && this.services.collection && this.services.collection.length>0){
        debugger;
        this.SetServicesURLs();
        this.checkImageUrl();
      }
    });
    // this.homeExcavationServicesSectionResources = homeExcavationServicesSectionResources;

  }

  SetServicesURLs() {
    if (this.services.collection.length > 0)
      this.services.collection.forEach(element => {
        // TODO replace # with culture on Eservices Bug fixed
        // element.ServiceURL =this.globals.ServicesBaseUrl+"/"+this.globals.Culture+"/details/" + element.id;
        element.ServiceURL =this.globals.ServicesBaseUrl+"/#/details/" + element.id;
      });

  }

  public checkImageUrl() {
    debugger;
    for (let index = 0; index < this.services.collection.length; index++) 
    {
      debugger;
        let item = this.services.collection[index];
        if(item.image)
        item.image = item.image.replace("http://","https://");
    }
}



}




