import { Injectable } from '@angular/core';
declare var commonLocalizedResources:any;
declare var viewLocalizedResources: any;
declare var innerLocalizedResources: any;
declare var eventsSectionResources: any;
declare var ChildrenLocalizedResources: any;
declare var mapLocalizedResources:any;
declare var culture:any;
declare var baseUrl:any;
declare var servicesBaseUrl:any;
declare var eServicesBaseUrl:any;

declare var missionsSectionResources:any;

@Injectable()
export class Globals {
    // @HostListener('window:resize', ['$event'])
    // onResize(event) {
    //     event.target.innerWidth;
    // }

    Globals(){
    }    
    
    static Culture: any=culture
    static BaseUrl:any=baseUrl+'Umbraco/Api';
    static EServicesBaseUrl:any=eServicesBaseUrl;
    static ServicesBaseUrl:any=servicesBaseUrl;
    static BaseMerchelloUrl:any=baseUrl+'umbraco/Merchello';
    static CommonLocalizedResources:any =commonLocalizedResources?commonLocalizedResources:"";    
    static ViewLocalizedResources:any =viewLocalizedResources?viewLocalizedResources:"";
    static InnerLocalizedResources: any = innerLocalizedResources?innerLocalizedResources:"";
    static EventsSectionResources: any = eventsSectionResources?eventsSectionResources:"";
    static ChildrenLocalizedResources: any = ChildrenLocalizedResources?ChildrenLocalizedResources:"";
    static MapLocalizedResources: any = mapLocalizedResources?mapLocalizedResources:"";
    static MissionsSectionResources: any = missionsSectionResources?missionsSectionResources:"";

    static PeriodDynastyDetailsCrop = "periodDynastyDetailsCrop";
    static MainDetailsCrop = "mainDetailsCrop";
    static PeriodListingCrop = "periodListingCrop"
    static SideListingCrop = "sideListingCrop";
    static MummyGameDetailsCrop = "mummyGameDetailsCrop";
    static SecondaryListingCrop = "secondaryListingCrop";
    static MummyGameListingCrop="mummyGameListingCrop";
    static ColorGameDetailsCrop="colorGameDetailsCrop";
    static ColorGameListingCrop="colorGameListingCrop";

    // static trimString(width: number, height: number, content: string, stringHeight: number, stringWidth: number){
    //     debugger;
    // }


}
