import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { GenericResponseContract } from '../../../../../shared/models/GenericResponseContract'
import { GenericListItemContract } from '../../../../../shared/models/GenericListItemContract'
import { Globals } from '../../../../../shared/models/Globals';
import { GenericListContract } from '../../../../../shared/models/GenericListContract';
import { CustomerBasketDTO } from 'projects/EshopApp/src/app/contracts/CustomerBasketDTO';
declare var $: any;
declare var eshopSectionResources: any;

@Component({
  selector: 'home-eshop-featured-products',
  templateUrl: './home-eshop-featured-products.component.html',
  styleUrls: []
})
export class HomeEshopFeaturedproductsComponent {
  featuredproducts: GenericListItemContract[] = new Array<GenericListItemContract>();
  CustomerBasket: any = new CustomerBasketDTO(); 
  globals;
  eshopSectionResources;
  rtl = Globals.Culture == "ar";

  slideConfig = {
    "infinite": true,
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "arrows": true,
    "nextArrow": '.shopItemsCont .nextFeaturedItemsArrow',
    "prevArrow": '.shopItemsCont .prevFeaturedItemsArrow',
    "touchThreshold": 3,
    "rtl": this.rtl,
    "responsive": [
      {
        "breakpoint": 825,
        "settings": {
          "infinite": false,
          "slidesToShow": 2,
          "arrows": false,
        }
      }, {
        "breakpoint": 480,
        "settings": {
          "infinite": false,
          "slidesToShow": 1,
        }
      }
    ]
  };




  constructor(private apiService: AppService) { }
  ngOnInit() {
    this.globals = Globals;
    this.eshopSectionResources = eshopSectionResources;
    this.getFeaturedproducts();
  }
  public getFeaturedproducts() {
    this.apiService.getFeaturedproducts().subscribe((response: GenericResponseContract<GenericListContract<GenericListItemContract>>) => {
      if (response && response.Data)
        this.featuredproducts = response.Data.ListItems;

      console.log(response);
    });
  }

  AddBasketItem(e, productKey) {
    this.apiService.AddBasketItem(productKey)
      .subscribe(
        (response: object) => {
          this.getCustomerBasketInfo();
          console.log(response);
          $(e.target).addClass('active');
          setTimeout(function () {
            $(e.target).removeClass('active');
          }, 1000)
        }
    );
   
  }

  getCustomerBasketInfo(){
		this.apiService
		.GetCustomerBasketInfo()
		.subscribe(
			response => {
				this.CustomerBasket = response["Data"];
				document.getElementById("basketcount").innerHTML = this.CustomerBasket.TotalBasketQuantity;
			}
		)
	}
}




