import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { GenericListContract } from 'projects/shared/models/GenericListContract';
import { GenericListItemContract } from 'projects/shared/models/GenericListItemContract';
import { GenericResponseContract } from 'projects/shared/models/GenericResponseContract';
import { Globals } from 'projects/shared/models/Globals';
declare var $: any;

@Component({
  selector: 'app-home-featured-announcement',
  templateUrl: './home-featured-announcement.component.html',
  styleUrls: []
})
export class HomeFeaturedAnnouncementComponent {
  featuredAnnouncements: GenericListItemContract[] = new Array<GenericListItemContract>();
  globals;
  rtl = Globals.Culture == "ar";
  slideConfig = {
    "infinite": true,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "speed": 0,
    "arrows": true,
    "nextArrow": ".nextArrowAnn",
    "prevArrow": ".prevArrowAnn",
    "rtl": this.rtl,
    "responsive": [
      {
        "breakpoint": 825,
        "settings": {
          "infinite": false,
          "speed": 500,
          "arrows": false,
          "centerMode": true,
          "centerPadding": '0px'
        }
      }
    ]
  };

  constructor(private apiService: AppService) { }
  ngOnInit() {
    this.globals = Globals;
    this.getFeaturedAnnouncements();
  }
  public getFeaturedAnnouncements() {
    this.apiService.getFeaturedAnnouncements().subscribe((response: GenericResponseContract<GenericListContract<GenericListItemContract>>) => {
      this.featuredAnnouncements = response.Data.ListItems;
      if (response.Data.ListItems.length > 0) {
        $(".notificationContainer .notsNum").text(response.Data.ListItems.length)
        $(".notificationContainer").show();
      }
    });
  }

}
