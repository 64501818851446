import { BrowserModule } from '@angular/platform-browser';
import {NgModule, ApplicationRef} from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; 
import {CommonModule} from '@angular/common'
import { SlickModule } from 'ngx-slick';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {HomeFeaturedMuseumComponent } from './components/home-featured-museum/home-featured-museum.component';
import {HomeFeaturedCitiesComponent } from './components/home-featured-cities/home-featured-cities.component';
import { HomeFeaturedUnderwaterTreasuresComponent } from './components/home-featured-underwater-treasures/home-featured-underwater-treasures.component';
import { HomeArchaeologicalSiteSectionComponent } from './components/home-archaeological-site-section/home-archaeological-site-section.component';
import { HomeEventSectionComponent } from './components/home-event-section/home-event-section.component'
import { HomeFeaturedAnnouncementComponent } from './components/home-featured-announcement/home-featured-announcement.component';
import { HomeChildWorldSectionComponent } from './components/home-child-world-section/home-child-world-section.component';
import { HomeEshopFeaturedproductsComponent } from './components/home-eshop-featured-products/home-eshop-featured-products.component';
import { HomeEshopBestsellingComponent } from './components/home-eshop-bestselling-products/home-eshop-bestselling-products.component';

import {MediaItemPopupComponent}  from '../../../shared/components/media-Item-popup/media-Item-popup.component';

import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import localeFr from '@angular/common/locales/fr';
import {
  SwiperModule,
  SwiperConfigInterface,
  SWIPER_CONFIG
} from "ngx-swiper-wrapper";
import { ProductRatingComponent } from 'projects/shared/components/product-rating/product-rating.component';
import { SafeHtmlPipe } from 'projects/shared/Pipes/SafeHtmlPipe';
import { HomeExcavationServicesSectionComponent } from './components/home-excavation-services-section/home-excavation-services-section.component';
registerLocaleData(localeAr, 'ar');
registerLocaleData(localeFr, 'fr');

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {};
declare var $: any;

@NgModule({
  declarations: [
    HomeFeaturedMuseumComponent,
    HomeFeaturedCitiesComponent,
    HomeFeaturedUnderwaterTreasuresComponent,
    HomeArchaeologicalSiteSectionComponent,
    HomeEventSectionComponent,
    HomeFeaturedAnnouncementComponent,
    HomeChildWorldSectionComponent,
    MediaItemPopupComponent,
    HomeEshopFeaturedproductsComponent,
    HomeEshopBestsellingComponent,
    ProductRatingComponent,
    HomeExcavationServicesSectionComponent,
    SafeHtmlPipe
  ],
  imports: [
    SwiperModule,
    BrowserModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    SlickModule.forRoot()
  ],
  providers: [{
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  }],
  entryComponents: [
    HomeFeaturedMuseumComponent,
    HomeFeaturedCitiesComponent,
    HomeFeaturedUnderwaterTreasuresComponent,
    HomeArchaeologicalSiteSectionComponent,
    HomeEventSectionComponent,
    HomeFeaturedAnnouncementComponent,
    HomeChildWorldSectionComponent,
    HomeEshopFeaturedproductsComponent,
    HomeEshopBestsellingComponent,
    HomeExcavationServicesSectionComponent
  ]
})
export class AppModule {

  constructor() {
  }

  ngDoBootstrap(appRef: ApplicationRef) {
    var homeFeaturedMuseumElement = $('home-featured-museum')[0];
    if (homeFeaturedMuseumElement) {
        appRef.bootstrap(HomeFeaturedMuseumComponent, homeFeaturedMuseumElement);
    }

    var homeFeaturedCitiesElement= $('home-featured-cities')[0];
    if (homeFeaturedCitiesElement) {
        appRef.bootstrap(HomeFeaturedCitiesComponent, homeFeaturedCitiesElement);
    }

    var homeFeaturedUnderwaterTreasuresElement = $('home-featured-underwater-treasures')[0];
    if (homeFeaturedUnderwaterTreasuresElement) {
        appRef.bootstrap(HomeFeaturedUnderwaterTreasuresComponent, homeFeaturedUnderwaterTreasuresElement);
    }

    var homeArchaeologicalSiteSectionElement = $('home-archaeological-site-section')[0];
    if (homeArchaeologicalSiteSectionElement) {
        appRef.bootstrap(HomeArchaeologicalSiteSectionComponent, homeArchaeologicalSiteSectionElement);
    }

    var homeEventSectionElement = $('home-event-section')[0];
    if (homeEventSectionElement) {
        appRef.bootstrap(HomeEventSectionComponent, homeEventSectionElement);
    }

    var homeFeaturedAnnouncementElement = $('app-home-featured-announcement')[0];
    if (homeFeaturedAnnouncementElement) {
        appRef.bootstrap(HomeFeaturedAnnouncementComponent, homeFeaturedAnnouncementElement);
    }
    var homeChildWorldSectionElement = $('home-child-world-section')[0];
    if (homeChildWorldSectionElement) {
        appRef.bootstrap(HomeChildWorldSectionComponent, homeChildWorldSectionElement);
    }

    var homeEshopFeaturedproductsElement = $('home-eshop-featured-products')[0];
    if (homeEshopFeaturedproductsElement) {
        appRef.bootstrap(HomeEshopFeaturedproductsComponent, homeEshopFeaturedproductsElement);
    }

    var homeEshopBestsellingElement = $('home-eshop-bestselling-products')[0];
    if (homeEshopBestsellingElement) {
        appRef.bootstrap(HomeEshopBestsellingComponent, homeEshopBestsellingElement);
    }

    var homeExcavationServicesElement = $('home-excavation-services-section')[0];
    if (homeExcavationServicesElement) {
        appRef.bootstrap(HomeExcavationServicesSectionComponent, homeExcavationServicesElement);
    }


  }
 }
