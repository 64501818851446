import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { GenericResponseContract } from 'projects/shared/models/GenericResponseContract'
import { GenericListItemContract } from 'projects/shared/models/GenericListItemContract'
import { Globals } from 'projects/shared/models/Globals';

@Component({
	selector: 'home-featured-museum',
	templateUrl: './home-featured-museum.component.html',
	styleUrls: []
})
export class HomeFeaturedMuseumComponent {
	featuredMuseum: GenericListItemContract = new GenericListItemContract();
	globals;
	rtl = Globals.Culture == "ar";
	slideConfig = {
		"infinite": true,
		"slidesToShow": 3,
		"slidesToScroll": 1,
		"speed": 1000,
		"arrows": true,
		"nextArrow": ".museumsNext",
		"prevArrow": ".museumsBack",
		"rtl": this.rtl,
		"centerMode": true,
		"centerPadding": '0px'
	};

	constructor(private apiService: AppService) { }
	ngOnInit() {
		this.globals = Globals;
		this.getFeaturedMuseum();
	}
	public getFeaturedMuseum() {
		this.apiService.getFeaturedMuseum().subscribe((response: GenericResponseContract<GenericListItemContract>) => {
			this.featuredMuseum = response.Data;
			console.log(response);
		});
	}

}




