import { GenericListContract } from "./GenericListContract";

export class GenericListItemContract {

    constructor(){
        this.InnerList=new GenericListContract();
    }
    Id:number;
    Title:string;
    Description:string;
    ImageUrl:string;
    ContentUrlName:string;
    InnerList:GenericListContract<GenericListItemContract>
    MediaList:GenericListContract<GenericListItemContract>
    }