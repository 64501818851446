import { Component } from '@angular/core';
import { AppService} from '../../services/app.service';
import { GenericListContract } from 'projects/shared/models/GenericListContract';
import { GenericListItemContract } from 'projects/shared/models/GenericListItemContract';
import { GenericResponseContract } from 'projects/shared/models/GenericResponseContract';
import { Globals } from 'projects/shared/models/Globals';

declare var egyptianMuseumsSectionResources:any;

@Component({
  selector: 'home-featured-cities',
  templateUrl: './home-featured-cities.component.html',
  styleUrls: []
})

export class HomeFeaturedCitiesComponent {
  featuredCities:  GenericListItemContract[] = new Array<GenericListItemContract>();
  globals;    
  egyptianMuseumsSectionResources;

  constructor(private  apiService:  AppService) { }
  ngOnInit() {
      this.globals=Globals;    
      this.egyptianMuseumsSectionResources=egyptianMuseumsSectionResources;
      this.getFeaturedCities();
  }
  public  getFeaturedCities(){
      this.apiService.getFeaturedCities().subscribe((response:  GenericResponseContract<GenericListContract<GenericListItemContract>>) => {
          this.featuredCities  =  response.Data.ListItems;
          
          console.log(response);
      });
  }

}




