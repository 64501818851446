import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { Globals } from 'projects/shared/models/Globals';

declare var $: any;

@Component({
  selector: 'home-child-world-section',
  templateUrl: './home-child-world-section.component.html',
	styleUrls: []
})
export class HomeChildWorldSectionComponent implements OnInit {

  public events: any;
  globals;

  constructor(private apiService: AppService) 
  { 
    this.globals = Globals;
  }

  ngOnInit() {
    this.apiService.getChildrenEvents()
			.subscribe(response => {
				this.events = response
			});
  }

  rtl = Globals.Culture == "ar";
  slideConfig = {
    "vertical": true,
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "arrows": false,
    "centerMode": false,
    "infinite": false,
    "centerPadding": '0px',
    "responsive": [
      {
        "breakpoint": 1025,
        "settings": {
          "vertical": false,
          "slidesToShow": 2,
        }
      },
      {
        "breakpoint": 769,
        "settings": {
          "vertical": false,
          "slidesToShow": 1,
          "slidesToScroll": 1,
          "arrows": false,
          "centerMode": true,
          "infinite": false,
          "centerPadding": '0px',
        }
      },
      
    ]
  };
}
