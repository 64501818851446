import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { GenericListContract } from 'projects/shared/models/GenericListContract';
import { GenericListItemContract } from 'projects/shared/models/GenericListItemContract';
import { GenericResponseContract } from 'projects/shared/models/GenericResponseContract';
import { Globals } from 'projects/shared/models/Globals';

@Component({
  selector: 'home-featured-underwater-treasures',
  templateUrl: './home-featured-underwater-treasures.component.html',
  styleUrls: []
})

export class HomeFeaturedUnderwaterTreasuresComponent {
  featuredUnderwaterTreasures: GenericListItemContract[] = new Array<GenericListItemContract>();
  globals;
  rtl = Globals.Culture == "ar";
  slideConfig = {
    "infinite": true,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "speed": 0,
    "arrows": true,
    "nextArrow": ".nextArrow",
    "prevArrow": ".prevArrow",
    "rtl": this.rtl,
    "responsive": [
      {
        "breakpoint": 825,
        "settings": {
          "infinite": false,
          "speed": 500,
          "arrows": false,
          "centerMode": true,
          "centerPadding": '0px'
        }
      }
    ]
  };

  constructor(private apiService: AppService) { }
  ngOnInit() {
    this.globals = Globals;
    this.getFeaturedUnderwaterTreasures();
  }
  public getFeaturedUnderwaterTreasures() {
    this.apiService.getFeaturedUnderwaterTreasures().subscribe((response: GenericResponseContract<GenericListContract<GenericListItemContract>>) => {
      this.featuredUnderwaterTreasures = response.Data.ListItems;

      console.log(response);
    });
  }

}




