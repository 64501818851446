
export class CustomerBasketDTO {

    TotalBasketQuantity: number = 0;
    TotalBasketPrice: number = 0;

    constructor(data?: CustomerBasketDTO) {
        if (data) {
          for (var property in data) {
            if (data.hasOwnProperty(property))
              (<any>this)[property] = (<any>data)[property];
          }
        }
      }
}