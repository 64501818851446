import { Component } from '@angular/core';
import {AppService} from '../../services/app.service';
import { GenericListContract } from '../../../../../shared/models/GenericListContract';
import { GenericListItemContract } from '../../../../../shared/models/GenericListItemContract';
import { GenericResponseContract } from '../../../../../shared/models/GenericResponseContract';
import { Globals } from '../../../../../shared/models/Globals';
import { CustomerBasketDTO } from 'projects/EshopApp/src/app/contracts/CustomerBasketDTO';

declare var $: any;

declare var eshopSectionResources:any;

@Component({
  selector: 'home-eshop-bestselling-products',
  templateUrl: './home-eshop-bestselling-products.component.html',
  styleUrls: []
})

export class HomeEshopBestsellingComponent {
  bestsellingProducts:  GenericListItemContract[] = new Array<GenericListItemContract>();
  CustomerBasket: any = new CustomerBasketDTO(); 
  globals;
  rtl = Globals.Culture == "ar";
  eshopSectionResources;

  slideConfig = {
    "infinite": false,
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "arrows": false,
    "touchThreshold": 3,
    "draggable": false,
    "rtl": this.rtl,
    "responsive": [
      {
        "breakpoint": 825,
        "settings": {
          "infinite": false,
          "slidesToShow": 2,
          "arrows": false,
          "draggable": true,

        }
      }, {
        "breakpoint": 480,
        "settings": {
          "infinite": false,
          "slidesToShow": 1,
        }
      }
    ]
  };


  constructor(private  apiService:  AppService) { }
  ngOnInit() {
      this.globals=Globals;    
      this.eshopSectionResources=eshopSectionResources;
      this.getBestsellingProducts();

  }
  public  getBestsellingProducts(){
      this.apiService.getBestsellingProducts().subscribe((response:  GenericResponseContract<GenericListContract<GenericListItemContract>>) => {
          if(response && response.Data)
                this.bestsellingProducts  =  response.Data.ListItems;
          
          console.log(response);
      });
  }

  AddBasketItem(e, productKey){
    this.apiService.AddBasketItem(productKey)
          .subscribe(
            (response: object) => {
              console.log(response);
              this.getCustomerBasketInfo();
              $(e.target).addClass('active');
              setTimeout(function () {
                $(e.target).removeClass('active');
              }, 1000)
            }
          );
    
    }

    getCustomerBasketInfo(){
      this.apiService
      .GetCustomerBasketInfo()
      .subscribe(
        response => {
          this.CustomerBasket = response["Data"];
          document.getElementById("basketcount").innerHTML = this.CustomerBasket.TotalBasketQuantity;
        }
      )
    }
}




